import classNames from 'classnames'
import React from 'react'
import {
  Add,
  ArrowDownward,
  ArrowUpward,
  EditOutlined,
  ChildCare,
  InfoOutlined,
  AssessmentOutlined,
  Language,
  Print,
  ImageOutlined,
  Cancel,
  OpenWith,
  Save,
  DeleteOutline,
  NavigateNext,
  Remove,
  GetApp,
  RestoreFromTrash,
  Check,
  Replay,
  CalendarTodayOutlined,
  ListOutlined,
  Star,
  ExpandLess,
  ExpandMore,
  ArrowDropUp,
  ArrowDropDown,
  OpenInNew,
  Notes,
  FilterList,
  CloseRounded,
  OfflineBoltRounded,
  PriorityHigh,
  WarningRounded,
} from '@material-ui/icons'
import './Icon.scss'

import { ReactComponent as CalendarIcon } from '../../../assets/icons/calendar.svg'
import { ReactComponent as LocationIcon } from '../../../assets/icons/location.svg'
import { ReactComponent as OrgIcon } from '../../../assets/icons/org.svg'
import { ReactComponent as PeopleIcon } from '../../../assets/icons/people.svg'
import { ReactComponent as SettingsIcon } from '../../../assets/icons/settings.svg'
import { ReactComponent as SpaceIcon } from '../../../assets/icons/space.svg'
import { ReactComponent as FloorIcon } from '../../../assets/icons/floors-icon.svg'
import { ReactComponent as PersonIcon } from '../../../assets/icons/person-icon.svg'
import { ReactComponent as CleaningSpray } from '../../../assets/icons/cleaning-spray.svg'
import { ReactComponent as CoffeeCupHot } from '../../../assets/icons/coffee-cup-hot.svg'
import { ReactComponent as CoffeeToGo } from '../../../assets/icons/coffee-to-go.svg'
import { ReactComponent as DisabilityWheelchair } from '../../../assets/icons/disability-wheelchair.svg'
import { ReactComponent as FitnessDumbbell } from '../../../assets/icons/fitness-dumbbell.svg'
import { ReactComponent as LockerRoomSuitcaseKey } from '../../../assets/icons/locker-room-suitcase-key.svg'
import { ReactComponent as LockerRoomWashHands } from '../../../assets/icons/locker-room-wash-hands.svg'
import { ReactComponent as ParkingP } from '../../../assets/icons/parking-p.svg'
import { ReactComponent as PhoneActionsRing } from '../../../assets/icons/phone-actions-ring.svg'
import { ReactComponent as Printer } from '../../../assets/icons/printer.svg'
import { ReactComponent as RestaurantForkKnife } from '../../../assets/icons/restaurant-fork-knife.svg'
import { ReactComponent as ShopCashierMan } from '../../../assets/icons/shop-cashier-man.svg'
import { ReactComponent as Wifi } from '../../../assets/icons/wifi.svg'
import { ReactComponent as MeetingRemote } from '../../../assets/icons/meeting-remote.svg'
import { ReactComponent as ModernTvRemote } from '../../../assets/icons/modern-tv-remote.svg'
import { ReactComponent as Monitor } from '../../../assets/icons/monitor.svg'
import { ReactComponent as OfficeDesk } from '../../../assets/icons/office-desk.svg'
import { ReactComponent as PresentationAnalytics } from '../../../assets/icons/presentation-analytics.svg'
import { ReactComponent as AlarmBellSleep } from '../../../assets/icons/alarm-bell-sleep.svg'
import { ReactComponent as ExpandVertical } from '../../../assets/icons/expand-vertical.svg'
import { ReactComponent as TeamMeeting } from '../../../assets/icons/team-meeting.svg'
import { ReactComponent as OfficeEmployee } from '../../../assets/icons/office-employee.svg'
import { ReactComponent as Regular } from '../../../assets/icons/regular.svg'
import { ReactComponent as CloudShield } from '../../../assets/icons/cloud-shield.svg'
import { ReactComponent as EmailActionEdit } from '../../../assets/icons/email-action-edit.svg'
import { ReactComponent as Clipboard } from '../../../assets/icons/clipboard.svg'
import { ReactComponent as PetsPaw } from '../../../assets/icons/pets-paw.svg'
import { ReactComponent as Shower } from '../../../assets/icons/shower.svg'
import { ReactComponent as BikeParking } from '../../../assets/icons/bike-parking.svg'
import { ReactComponent as NoPetsIcon } from '../../../assets/icons/no-pets.svg'

const variants = {
  calendar: <CalendarIcon />,
  calendarToday: <CalendarTodayOutlined />,
  list: <ListOutlined />,
  location: <LocationIcon />,
  org: <OrgIcon />,
  people: <PeopleIcon />,
  settings: <SettingsIcon />,
  space: <SpaceIcon />,
  transparent: <span />,
  add: <Add />,
  edit: <EditOutlined />,
  'child-care': <ChildCare />,
  info: <InfoOutlined />,
  floors: <FloorIcon />,
  person: <PersonIcon />,
  assessment: <AssessmentOutlined />,
  'cleaning-spray': <CleaningSpray />,
  'coffee-cup-hot': <CoffeeCupHot />,
  'coffee-to-go': <CoffeeToGo />,
  'disability-wheelchair': <DisabilityWheelchair />,
  'fitness-dumbbell': <FitnessDumbbell />,
  'locker-room-suitcase-key': <LockerRoomSuitcaseKey />,
  'locker-room-wash-hands': <LockerRoomWashHands />,
  'parking-p': <ParkingP />,
  'phone-actions-ring': <PhoneActionsRing />,
  printer: <Printer />,
  'restaurant-fork-knife': <RestaurantForkKnife />,
  'shop-cashier-man': <ShopCashierMan />,
  wifi: <Wifi />,
  'meeting-remote': <MeetingRemote />,
  'modern-tv-remote': <ModernTvRemote />,
  monitor: <Monitor />,
  'office-desk': <OfficeDesk />,
  'presentation-analytics': <PresentationAnalytics />,
  'alarm-bell-sleep': <AlarmBellSleep />,
  'expand-vertical': <ExpandVertical />,
  language: <Language />,
  print: <Print />,
  image: <ImageOutlined />,
  cancel: <Cancel />,
  move: <OpenWith />,
  save: <Save />,
  'team-meeting': <TeamMeeting />,
  'office-employee': <OfficeEmployee />,
  delete: <DeleteOutline />,
  'navigate-next': <NavigateNext />,
  remove: <Remove />,
  regular: <Regular />,
  'cloud-shield': <CloudShield />,
  'email-action-edit': <EmailActionEdit />,
  download: <GetApp />,
  'restore-from-trash': <RestoreFromTrash />,
  copy: <Clipboard />,
  check: <Check />,
  reload: <Replay />,
  'pets-paw': <PetsPaw />,
  star: <Star />,
  downward: <ArrowDownward />,
  upward: <ArrowUpward />,
  expandLess: <ExpandLess />,
  expandMore: <ExpandMore />,
  shower: <Shower />,
  'bike-parking': <BikeParking />,
  dropUpArrow: <ArrowDropDown />,
  dropDownArrow: <ArrowDropUp />,
  openInNew: <OpenInNew />,
  altText: <Notes />,
  filter: <FilterList />,
  close: <CloseRounded />,
  integration: <OfflineBoltRounded />,
  priorityHigh: <PriorityHigh />,
  warning: <WarningRounded />,
  no_pets: <NoPetsIcon />,
}

export type IconVariant = keyof typeof variants

export interface IconProps {
  variant: IconVariant
  className?: string
  onClick?: (event: any) => void
  style?: React.CSSProperties
}

export const Icon = (props: IconProps) => {
  return (
    <span
      className={classNames('Icon', props.className, props.variant)}
      onClick={props.onClick}
      style={props.style}
    >
      {variants[props.variant]}
    </span>
  )
}
