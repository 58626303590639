import React from 'react'
import './Titles.scss'
import classNames from 'classnames'

interface TitleProps {
  children: React.ReactNode
  secondary?: boolean
  className?: string
  muted?: boolean
}

const createTitleComponent = (component: string): React.FC<TitleProps> => {
  return (childProps: TitleProps) => {
    const { className, secondary, muted, ...restProps } = childProps

    return React.createElement(component, {
      className: classNames(className, {
        Title: true,
        [`Title--${component}`]: true,
        secondary,
        'Title--muted': muted,
      }),
      ...restProps,
    })
  }
}

export default {
  H1: createTitleComponent('h1'),
  H2: createTitleComponent('h2'),
  H3: createTitleComponent('h3'),
  H4: createTitleComponent('h4'),
  H5: createTitleComponent('h5'),
  H6: createTitleComponent('h6'),
}
