export const taxTypes = ['sales_tax', 'vat'] as const

export const taxRateCountryCodes = [
  'US',
  'FI',
  'CN',
  'CA',
  'SE',
  'AT',
  'GB',
] as const

export const currencyCodes = [
  'usd',
  'eur',
  'cny',
  'cad',
  'sek',
  'gbp',
  'pln',
  'chf',
  'nok',
  'dkk',
  'ron',
  'czk',
] as const

export const adminRoles = [
  'super-admin-billing',
  'super-admin',
  'admin-reservations',
  'admin-organizations',
  'admin-announcements',
  'admin-people',
  'admin-spaces',
  'admin-space-statistics',
  'admin-savings-calculator',
  'admin-user-statistics',
] as const

export const amenities = [
  'print_scan_copy',
  'coffee_tea',
  'restaurant',
  'cafe',
  'hand_sanitizer',
  'enhanced_cleaning',
  'wifi',
  'parking',
  'accessible',
  'lockers',
  'reception',
  'phone_booth',
  'gym',
  'adjustable_table',
  'monitor',
  'silent_space',
  'whiteboard',
  'projector',
  'videoconference',
  'furnished',
  'pet_friendly',
  'shower',
  'bike_parking',
  'no_pets',
] as const

export const languages = ['fi', 'en', 'zh', 'sv'] as const

export const weekDays = [
  'monday',
  'tuesday',
  'wednesday',
  'thursday',
  'friday',
  'saturday',
  'sunday',
] as const

export const ICP = '沪ICP备15005341'

export const publishingStatuses = [
  'draft',
  'hidden',
  'inReview',
  'live',
  'internal',
  'hidden-from-calendars', // unavailability
  'deleted', // Should act as if it's been deleted, but would be easily recoverable.
  'testing', // Status for blocking hosts from setting their spaces live
] as const

export const publishingStatusEmojiMap: Record<
  typeof publishingStatuses[number],
  string
> = {
  inReview: '🔍',
  live: '🟢',
  draft: '📝',
  internal: '🟠',
  hidden: '🔴',
  'hidden-from-calendars': '🔵',
  deleted: '❌',
  testing: '🟣',
}

export const possibleUserFeatureFlags = [
  'FEATURE_DISPLAY_PROJECT_SPACES',
  'FEATURE_COLLEAGUES_VIEW',
  'FEATURE_TOP_LEVEL_COLLEAGUES',
  'FEATURE_SEARCH',
  'FEATURE_GUEST_CHECK_IN',
  'FEATURE_INVITE_A_GUEST',
  'FEATURE_QUICK_FEEDBACK',
  'FEATURE_RESERVATION_SUGGESTIONS',
  'FEATURE_TAG_FAVORITES',
  'FEATURE_ORGUSER_CREDITCARD',
  'FEATURE_SPACE_NEXT_AVAILABLE_SLOT',
  'FEATURE_HOME_ADDRESS',
  'FEATURE_TEAM_SPACES',
  'FEATURE_UPGRADE',
] as const

export const meetingRoomPackageMachineName = 'meeting-room-package'
export const DefaultInternalOfferingName = 'my-organization'
export const DefaultInternalFreeOfferName = 'organization-internal-free'
export const DefaultSubscriptionOfferingName = 'spacent-membership-holders'
export const DefaultSubscriptionOfferName = 'spacent-membership'
export const GlobalBasicMembershipOfferName = 'global-basic-membership-offer'
export const GlobalBasicMembershipOfferingName = 'basic-membership-offering'
export const GlobalBusinessMembershipOfferName =
  'global-business-membership-offer'
export const GlobalBusinessMembershipOfferingName =
  'business-membership-offering'
export const GlobalBusinessPlusMembershipOfferName =
  'global-business-plus-membership-offer'
export const GlobalBusinessPlusMembershipOfferingName =
  'business-plus-membership-offering'
export const DefaultPublicOfferingName = 'all-spacent-users'
export const LegacySubscriptionUsergroupName = 'Spacent Membership'
export const BasicMembershipUsergroupName = 'Basic Membership'
export const BusinessMembershipUsergroupName = 'Business Membership'
export const BusinessPlusMembershipUsergroupName = 'Business Plus Membership'
export const DefaultAllUsersUsergroupName = 'All users'
export const SimplePricingEditorOfferNamePrefix = 'simple-pricing-'
export const AllMembershipsOfferingName = 'all-memberships-offering'
export const BothBusinessMembershipsOfferingName =
  'both-business-memberships-offering'
export const TTKClientsUsergroupName = 'TTK Clients'
export const SPACENT_ORG_ID = 3

export enum Questionnaires {
  HeardFromSource = 'heard-from-source',
}

export const heardFromSourcesForOrganizationUsers = [
  'company_communication_channel',
  'company_event',
  'colleagues',
  'social_media',
  'other',
  'prefer_not_to_say',
] as const

export const heardFromSourcesForIndividualUsers = [
  'search_engine',
  'other_social_media',
  'email_messaging',
  'word_of_mouth',
  'other',
  'prefer_not_to_say',
] as const

export const heardFromSources = [
  ...heardFromSourcesForOrganizationUsers,
  ...heardFromSourcesForIndividualUsers,
] as const

export type HeardFromSource = typeof heardFromSources[number]

export const TTK_ORG_ID = 155
export const SWEDISH_LEGISLATION_REGION_ID = 6
export const FINNISH_LEGISLATION_REGION_ID = 1
export const UK_LEGISLATION_REGION_ID = 21

export enum MembershipType {
  Basic = 'basic',
  Business = 'business',
  BusinessPlus = 'business-plus',
}
