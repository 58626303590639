import autoAnimate from '@formkit/auto-animate'
import { useRef, useEffect, useState } from 'react'

export const useAutoAnimate = () => {
  const parent = useRef(null)

  useEffect(
    (config?: Parameters<typeof autoAnimate>[1]) => {
      parent.current && autoAnimate(parent.current as any, config)
    },
    [parent],
  )

  return [parent]
}

export const useDelayedSpinner = (delay: number) => {
  const [loading, setLoading] = useState(false)
  const [showSpinner, setShowSpinner] = useState(false)

  useEffect(() => {
    let timer: any

    if (loading) {
      timer = setTimeout(() => {
        setShowSpinner(true)
      }, delay)
    } else {
      setShowSpinner(false)
    }

    return () => clearTimeout(timer)
  }, [loading, delay])

  return { loading: showSpinner, setLoading }
}
